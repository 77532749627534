<template>
    <div v-if="exceptDay" class="row m-0">
        <div class="col-6 mb-2">
            <label for="exceptName">예외일명</label>
            <input v-model="exceptDay.exceptName" type="text" class="form-control" placeholder="예외일명">
        </div>
        <div class="col-6">
            <label for="exceptDt">예외일자</label>
            <!-- <input v-model="exceptDay.exceptDt" type="text" class="form-control"> -->
            <date-picker v-model="exceptDay.exceptDt" placeholder="예외일자" vertical
                :config="{ format: 'YYYY-MM-DD', locale: 'ko' }"></date-picker>
        </div>
    </div>
</template>

<script>

export default {
    props: ["data"],
    components: {},
    data() {
        return {
            exceptDay: null,
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        newData() {
            return {
                schdCtrlIdx: this.data.schdCtrlIdx,
                exceptName: '',
                exceptDt: '',
                enabled: 'Y',
            }
        },
        async setData(data) {
            if (data) {
                this.exceptDay = data;
            } else {
                this.exceptDay = this.newData();
            }
        },
        getData() {
            return this.exceptDay;
        },
    }
}

</script>

