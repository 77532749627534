<template>
    <div class="container">
        <div class="row flex-grow-0 mb-2">
            <div class="col-3">
                <label for="schdCtrlName">
                    <span>{{ $t('스케줄명') }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div>
                    <input v-model="detailInfo.schdCtrlName" type="text" class="form-control" id="name"
                        placeholder="스케줄명" :disabled="!isEditMode">
                </div>
            </div>
            <div class="col-3">
                <label for="zoneIdx">{{ $t('적용구역') }}</label>
                <select v-model="detailInfo.zoneIdx" class="form-control" id="zoneIdx" :disabled="!isEditMode">
                    <option v-for="(zone, i) in this.$store.state.zoneInfo" :key="i" :value="zone.value">
                        {{ zone.text }}
                    </option>
                </select>
            </div>
            <div class="col-3">
                <label for="startDate">{{ $t('시작일자') }}</label>
                <date-picker v-model="detailInfo.startDate" class="form-control" placeholder="시작일자" vertical
                    :config="{ format: 'YYYY-MM-DD', locale: 'ko', viewMode: 'days' }" :disabled="!isEditMode"></date-picker>
            </div>
            <div class="col-3">
                <label for="endDate">{{ $t('종료일자') }}</label>
                <date-picker v-model="detailInfo.endDate" class="form-control" placeholder="종료일자" vertical
                    :config="{ format: 'YYYY-MM-DD', locale: 'ko', viewMode: 'days' }" :disabled="!isEditMode"></date-picker>
            </div>
        </div>
        <div class="row flex-grow-0 mb-2">
            <!-- {{ detailInfo.repeatConfig }} -->
            <div class="col-12">
                <label for="endDate">{{ $t('요일선택') }}</label>
                <div class="col-sm-12 repeat-config">
                    <div v-for="(day, i) in day" :key="i" class="form-check mr-1">
                        <input v-model="detailInfo.repeatConfig[day.key]" class="form-check-input" type="checkbox"
                            :id="day.key" :disabled="!isEditMode">
                        <label class="form-check-label" :for="day.key">{{ day.value }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row flex-grow-0 mb-2">
            <div class="col-6">
                <label for="equipType">설비 유형</label>
                <div id="equipType">
                    <div class="equip-type-ctn">
                        <div v-for="type in equipTypeList" :key="type.equipType" class="form-check">
                            <input v-model="type.checked" class="form-check-input" type="checkbox" 
                                :id="type.equipType" :disabled="!isEditMode">
                            <label class="form-check-label" :for="type.equipType">{{ type.equipTypeName }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <label for="description">{{ $t('설명') }}</label>
                <textarea v-model="detailInfo.description" class="desc-ctn form-control" id="description" :disabled="!isEditMode"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
// import backEndApi from "@src/api/backEndApi";

export default {
    props: ['detailInfo', 'mode', 'equipTypeList'],
    components: {},
    data() {
        return {
            day: [
                { key: "Mon", value: "월" },
                { key: "Tue", value: "화" },
                { key: "Wed", value: "수" },
                { key: "Thu", value: "목" },
                { key: "Fri", value: "금" },
                { key: "Sat", value: "토" },
                { key: "Sun", value: "일" },
            ],
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
        isNewMode() {
            return this.mode === 'new';
        }
    },
    watch: {
        // isNewMode(newVal) {
        //     if (newVal) {
        //         this.setEquipTypeChecked();
        //     }
        // },
        // detailInfo(newVal) {
        //     if (newVal) {
        //         this.setEquipTypeChecked();
        //     }
        // }
    },
    created() {
        
    },
    mounted() { },
    methods: {
        // async getEquipTypeList() {
        //     const list = this.$store.state.equipTypeList;
        //     this.equipTypeList = list.filter(item => !this.isEmpty(item.ctrlModeCnt));
        //     await this.setEquipTypeChecked()
        // },
        // setEquipTypeChecked() {
        //     this.equipTypeList.forEach(type => {
        //         let find = this.detailInfo.targets.find(target => target.equipType == type.equipType);
        //         if (find) type.checked = true;
        //         else type.checked = false;
        //     })
        // },
    },
}

</script>

<style scoped>
.repeat-config {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #d5dbe0;
    border-radius: 5px;
    padding: 7px;
}

.equip-type-ctn {
    height: 100px;
    overflow-y: auto;
    border: 1px solid #d5dbe0;
    border-radius: 5px;
    padding: 10px;
}

.desc-ctn {
    height: 100px;
    resize: none;
    overflow-y: auto;
}
</style>