<template>
    <div class="ld-container" style="height: 90vh; width: 100%">
        <div class="row">
            <HeaderBox :title="`${$t('건물 운영 관리')} > ${$t('제어 스케줄 관리')}`" />
        </div>
        <div class="row" style="height: calc(100% - 40px);">
            <div class="list-area col-md-6">
                <TableList ref="table-list" :title="'제어 스케줄'" :columns="columns" :rows="schdList"
                    :keyField="'schdCtrlIdx'" :toggleFields="['enabled']" :fixedHeader="false" :useFilter="false"
                    :transCodes="transCodes" :controls="controls" :mode="'edit'" :useMaxHeight="true" :isSort="false"
                    @row-click="handleItemClick" @toggle-changed="handleItemToggle" @button-click="handleListButtonClick"
                />
            </div>
            <div class="detail-area col-md-6">
                <SchdCtrlDetail ref="SchdCtrlDetail" :title="'제어 스케줄'" :schdCtrlIdx="schdCtrlIdx"
                    @data-updated="handleDetailUpdated" @closed="handleDetailClosed" />
            </div>
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import HeaderBox from "@views/component/headerBox/analysis/Header.vue";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import SchdCtrlDetail from "./SchdCtrlDetail.vue"
import moment from 'moment'

export default {
    components: {
        HeaderBox,
        TableList,
        SchdCtrlDetail
    },
    data() {
        return{
            schdList: [],
            schdCtrlIdx: null,
            controls: [
                { event: 'delete', label: '삭제', class: 'btn-danger', },
                { event: 'status', label: '사용', class: 'bg-white text-dark', options: { enabled: 'Y' }, },
                { event: 'status', label: '미사용', class: 'bg-white text-dark', options: { enabled: 'N' }, },
                { event: 'regist', label: '추가', class: 'btn-primary', },
            ],
            transCodes: [
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: 'zoneIdx',
                    codes: this.$store.state.zoneInfo,
                },
            ],
            columns: [
                {
                    label: this.$t("스케줄명"),
                    field: "rowTitle",
                    type: "text",
                    compositionFields: ['schdCtrlName', 'schdCtrlIdx'],
                },
                {
                    label: this.$t("제어 구역"),
                    field: "zoneIdx",
                    width: "70px",
                    type: "text",
                },
                {
                    label: this.$t("제어 대상"),
                    field: "ctrlTarget",
                    type: "text",
                },
                {
                    label: this.$t("제어 요일"),
                    field: "repeatConfig",
                    type: "text",
                },
                {
                    label: this.$t("제어 스케줄"),
                    field: "events",
                    width: "120px",
                    type: "text",
                    tdClass: "text-left",
                },
                {
                    label: this.$t("유효 기간"),
                    field: "validity",
                    type: "text",
                },
                {
                    label: this.$t("마지막 실행 시간"),
                    field: "lastCtrlDate",
                    type: "text",
                },
                {
                    label: this.$t("실행 횟수"),
                    field: "ctrlCnt",
                    width: "70px",
                    type: "text",
                },
                {
                    label: this.$t("사용 여부"),
                    field: "enabled",
                    width: "70px",
                    type: "text",
                },
            ],
        }
    },
    computed: {},
    watch: {},
    async created() {
        await this.getSchdCtrlList();
    },
    mounted() {},
    methods: {
        async getSchdCtrlList() {
            this.schdList = [];
            try {
                let result = await backEndApi.equipControl.getSchdCtrlList();
                if (result.status == 200) {
                    result.data.forEach(item => {
                        let shcd = {
                            schdCtrlName: item.schdCtrlName,
                            schdCtrlIdx : item.schdCtrlIdx,
                            zoneIdx: item.zoneIdx,
                            ctrlTarget: this.setEquipList(item.equipTypes),
                            repeatConfig: item.repeatConfig,
                            events: this.ctrlSchdSettingText(item.events),
                            validity: this.setValidity(item.startDate, item.endDate),
                            lastCtrlDate: this.setCtrlCount(item.lastCtrlDate, item.lastSuccessCnt, item.lastFailCnt),
                            ctrlCnt: item.ctrlCnt,
                            enabled: item.enabled,
                        };
                        this.schdList.push(shcd)
                    })
                }
            } catch (e) {
                this.alertWarning("data fetch error")
                console.error(e)
            }
        },

        //setCtrlTarget
        setEquipList(ctrlTarget) {
            let targetList = ctrlTarget;
            let target = '';
            targetList.forEach((ctrl,idx) => {
                target += `${ctrl.equiptTypeCodeName ? ctrl.equiptTypeCodeName : '-'}<br>(${ctrl.cnt ? ctrl.cnt : '-'}) ${idx == targetList.length - 1 ? '' : '<br>'}`
            })
            return target;
        },

        setValidity(start, end) {
            let date = `${moment(start).format('YYYY-MM-DD')}<br> ~ ${moment(end).format('YYYY-MM-DD')}`;
            return date;
        },

        ctrlSchdSettingText(events) {
            let html = '';
            if (events.length > 0) {
                for (let i = 0; i < events.length; i++) {
                    html += `<div style="padding-bottom: ${i === events.length - 1 ? '0' : '5px'};"><span style="color: #fff; background-color: #97cba9; padding: 3px; border-radius: 3px; font-weight: bold;">${events[i].schdHour}</span> ${events[i].schdEventName}</div>`;
                }
            }
            return html;
        },

        setCtrlCount(lastCtrlDate, lastSuccessCnt, lastFailCnt) {
            return `<p style="margin-bottom: 0px !important;">${moment(lastCtrlDate).format('YYYY-MM-DD HH:mm:ss')}</p><span style="font-weight: bold; color: #2772db;">성공: ${lastSuccessCnt ?? 0} </span><span style="font-weight: bold; color: #ef5a5a;">실패: ${lastFailCnt ?? 0}</span>`
        },

        async handleDetailUpdated() {
            await this.getSchdCtrlList();
        },

        handleDetailClosed() {
            this.schdCtrlIdx = null;
        },

        async handleItemClick(row, idx, toggle, check) {
            if (toggle || check) return;

            const confirm = await this.$refs['SchdCtrlDetail'].notifyCancelEdit();
            if (confirm) {
                this.schdCtrlIdx = row.schdCtrlIdx;
            }
        },
        async handleListButtonClick(event, options) {
            
            switch (event) {
                case 'regist':
                    await this.registNew();
                    break;

                case 'delete':
                    await this.deleteChecked();
                    break;

                case 'refresh':
                    await this.getDataList(event);
                    break;

                case 'status':
                    await this.changeStatusChecked(options);
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }
        },

        async registNew() {
            // this.$refs['EquipTypeDetail'].newData();
            this.$refs.SchdCtrlDetail.newData();
        },

        async deleteChecked() {
            const that = this;
            const checked = this.$refs['table-list'].getCheckedRows();
            if (that.isEmpty(checked)) {
                this.alertNoti("삭제할 목록을 선택해주세요.");
                return;
            }
            try {
                const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                if (!confirm.value) return;

                checked.forEach(async (item) => {
                    try {
                        await backEndApi.equipControl.deleteEquipCtrlSch(item.schdCtrlIdx);
                    } catch (e) {
                        console.error('Fail to delete schdCtrlIdx', item);
                    }
                });
                await this.alertNoti("선택한 목록을 삭제하였습니다.")

                let clear = await this.getSchdCtrlList();
                if (clear) await this.$refs['SchdCtrlDetail'].clearData();
                else this.schdList = [];

            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },

        async changeStatusChecked(options) {
            let data = options;

            try {
                const checked = this.$refs['table-list'].getCheckedRows();
                if (!this.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        if (!this.isEmpty(data)) {
                            data['schdCtrlIdx'] = item.schdCtrlIdx;

                            if (data.hasOwnProperty('enabled')) {
                                await backEndApi.equipControl.setSchdCtrlEnabled(data);
                            } else {
                                console.warn('Unknown Status Name')
                            }
                        }
                    });
                    await this.alertNoti("변경하였습니다.");
                    await this.getSchdCtrlList();
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }

            } catch (e) {
                console.error(e)
                this.alertNoti("상태 변경에 실패하였습니다.");
            }
        },

        async handleItemToggle(key, field, status) {
            await this.changeStatus(key, field, status);
        },
        
        async changeStatus(key, field, status) {
            const data = {};
            data['schdCtrlIdx'] = key;
            data[field] = status;

            try {

                let result = await backEndApi.equipControl.setSchdCtrlEnabled(data);
                if (result.data) {
                    await this.alertNoti("변경하였습니다.");
                    await this.getSchdCtrlList();
                }
            } catch (e) {
                console.error(e)
                this.alertNoti("상태 변경에 실패하였습니다.");
            }
        },
    },
}

</script>

<style scoped>
.ld-container {
    height: 90vh;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    padding: 10px 30px 10px 20px;
}

.ld-container .list-area {
    height: 100%;
    overflow: hidden;
    padding: 10px;
}

.ld-container .detail-area {
    height: 100%;
    margin-right: 0px;
    padding: 0px 10px 0px 10px;

    border-radius: 10px;
    border-top: 1px solid #f4f4f4;
    border-right: 2px solid #e7e7e7;
    border-bottom: 2px solid #e7e7e7;
    border-left: 1px solid #f4f4f4;
    background-color: #f9f9f9;
}
</style>