<template>
    <div class="row" style="height: calc(100% - 40px);">
        <div class="col-md-12">
            <TableList ref="table-list" :title="''" :columns="columns" :rows="detailInfo.exceptConfig"
                :fixedHeader="false" :useFilter="false" :transCodes="transCodes" :keyField="'exceptDt'"
                :controls="controls" :mode="mode" :useMaxHeight="true" :toggleFields="['enabled']"
                @row-click="handleListItemClick" @button-click="handleListButtonClick" :isSort="false"
                @toggle-changed="handleItemToggle" />
        </div>
        <b-modal ref="ExceptionDayDetailModal" centered @hidden="closeDetailModal">
            <template #modal-header>
                <h5>{{ exceptIdx !== null || undefined ? $t("예외일 편집") : $t("예외일 추가") }}</h5>
            </template>
            <template #modal-footer centered>
                <div style="display: flex; flex: 1;">
                    <button v-show="isEditMode" type="button" class="btn btn-primary" style="flex: 1;"
                        @click="handleAddListItem">
                        저장
                    </button>
                    <button type="button" class="btn btn-secondary" style="flex: 1;" @click="closeDetailModal">
                        취소
                    </button>
                </div>
            </template>
            <ExceptionDayDetailPanel ref="ExceptionDayDetailPanel" :data="detailInfo" />
        </b-modal>
        <b-modal ref="ExceptionDayCopyModal" @hidden="closeExceptionDayCopy">
            <template #modal-header>
                <h5>{{ $t("예외일 불러오기") }}</h5>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button type="button" class="btn btn-primary" style="flex: 1;"
                        @click="addExceptCopyList">
                        저장
                    </button>
                    <button type="button" class="btn btn-secondary" style="flex: 1;"
                        @click="closeExceptionDayCopy">
                        취소
                    </button>
                </div>
            </template>
            <ExceptionDayCopyPanel ref="ExceptionDayCopyPanel" :data="detailInfo" />
        </b-modal>
    </div>
</template>

<script>
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import ExceptionDayDetailPanel from "./ExceptionDayDetailPanel.vue";
import ExceptionDayCopyPanel from "./ExceptionDayCopyPanel.vue"
// import moment from 'moment'

export default {
    props: ["detailInfo", "mode"],
    components: {
        TableList,
        ExceptionDayDetailPanel,
        ExceptionDayCopyPanel,
    },
    data() {
        return {
            exceptIdx: null,
            controls: [
                { event: 'copyExcept', label: '예외일 불러오기', class: 'btn-secondary', },
                { event: 'delete', label: '-', class: 'btn-danger', },
                { event: 'regist', label: '+', class: 'btn-primary', },
            ],
            transCodes: [
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            columns: [
                {
                    label: this.$t("예외일명"),
                    field: "exceptName",
                    type: "text",
                },
                {
                    label: this.$t("예외일자"),
                    field: "exceptDt",
                    type: "text",
                },
                {
                    label: this.$t("사용여부"),
                    field: "enabled",
                    type: "text",
                },
            ],
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        async handleListButtonClick(event, options) {
            const that = this;
            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteChecked();
                    break;

                    
                case 'copyExcept':
                    await that.showExceptionDayCopy();
                    break;

                default:
                    console.warn('Unhandled Component Except. event:', event, 'options:', options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            if (toggle || check) return;
            this.exceptIdx = index;
            let findExcept = this.detailInfo.exceptConfig.find(item => item.exceptDt == row.exceptDt)
            await this.$refs.ExceptionDayDetailModal.show();
            await this.$refs.ExceptionDayDetailPanel.setData(findExcept);
        },
        async registNew() {
            this.exceptIdx = null;
            this.$refs.ExceptionDayDetailModal.show();
            this.$nextTick(() => {
                this.$refs.ExceptionDayDetailPanel.setData(null);
            })
        },
        async addExceptCopyList() {
            const except = this.$refs.ExceptionDayCopyPanel.getData();
            if (except) {
                except.forEach(list => {
                    if (!this.detailInfo.exceptConfig.some(except => except.exceptDt == list.exceptDt))
                        this.detailInfo.exceptConfig.push(list)
                })
                this.detailInfo.exceptConfig.sort((a, b) => new Date(a.exceptDt) - new Date(b.exceptDt))
            }
            await this.closeExceptionDayCopy();
        },
        async deleteChecked() {
            try {
                const checked = this.$refs['table-list'].getCheckedRows();

                if (!this.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        this.detailInfo.exceptConfig = this.detailInfo.exceptConfig.filter(except => {
                            return !checked.find(checkedExcept => checkedExcept.exceptDt === except.exceptDt);
                        })
                    });
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },
        async handleAddListItem() {
            const except = this.$refs.ExceptionDayDetailPanel.getData();

            if (except) {
                if (this.exceptIdx !== null || undefined) {
                    this.$set(this.detailInfo.exceptConfig, this.exceptIdx, except);
                    this.exceptIdx = null;
                } else {
                    let check = this.detailInfo.exceptConfig.find(item => item.exceptDt === except.exceptDt);
                    if (check) {
                        // TODO: Alert Message
                        console.error('TODO: Alert Message');
                    } else {
                        this.$set(this.detailInfo.exceptConfig, this.detailInfo.exceptConfig.length, except);
                    }
                }
            }

            await this.closeDetailModal();
        },
        closeDetailModal() {
            if (this.exceptIdx !== null || undefined) this.exceptIdx = null;
            this.$refs.ExceptionDayDetailModal.hide();
        },
        handleItemToggle(key, field, status) {
            const found = this.detailInfo.exceptConfig.find(item => item.exceptDt === key)
            found.enabled = status
        },
        showExceptionDayCopy() {
            this.$refs.ExceptionDayCopyModal.show();
        },
        closeExceptionDayCopy() {
            this.$refs.ExceptionDayCopyModal.hide();
        }
    },
}

</script>

<style scoped></style>